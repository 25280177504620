<script>
	import { createEventDispatcher, onDestroy } from 'svelte';

	const dispatch = createEventDispatcher();
	const close = () => dispatch('close');
   

	let hcModal;

	const handle_keydown = e => {
		if (e.key === 'Escape') {
			close();
			return;
		}

		if (e.key === 'Tab') {
			// trap focus
			const nodes = hcModal.querySelectorAll('*');
			const tabbable = Array.from(nodes).filter(n => n.tabIndex >= 0);

			let index = tabbable.indexOf(document.activeElement);
			if (index === -1 && e.shiftKey) index = 0;

			index += tabbable.length + (e.shiftKey ? -1 : 1);
			index %= tabbable.length;

			tabbable[index].focus();
			e.preventDefault();
		}
	};

	const previously_focused = typeof document !== 'undefined' && document.activeElement;

	if (previously_focused) {
		onDestroy(() => {
			previously_focused.focus();
		});
	}
</script>

<svelte:window on:keydown={handle_keydown}/>

<div class="modal-background-hcaptcha" on:click={close}></div>

<div class="modal-hcaptcha" role="dialog" aria-modal="true" bind:this={hcModal}>
	<slot></slot>
	<!-- svelte-ignore a11y-autofocus -->
</div>

<style>
	.close {
		position: absolute;
		top: 0;
		right: 0;	
		padding: 20px;
	}

	.close:hover {
		cursor: pointer;
	}

	.modal-background-hcaptcha {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.2);
        z-index: 1;
	}

	.modal-hcaptcha {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
        z-index: 2;
	}
</style>