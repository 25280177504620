<script>
    import { tweened } from "svelte/motion";

    export let fixed = null;
    export let value = 0;
    let progress = tweened(0);

    $: progress.set(value < 100 ? value : 100);
</script>

<div class="progress">
    <div class="progress-bar">
        <div class="progress-value" style="width: {fixed ? fixed : $progress}%;" />
    </div>
</div>

<style>
    .progress {
        width: 60%;
        border-radius: 20px;
        margin: 10px 0px;
        height: 16px;
    }

    .progress-bar {
        width: 100%;
        height: 100%;
        background-color: #eaeaea;
		border-radius: 20px;
    }

    .progress-bar .progress-value {
        width: 0%;
        height: 100%;
        background-color: var(--theme-color);
        border-radius: 20px;
    }

    @media screen and (max-width: 425px) {
		.progress {
			width: 80%;
		}
	}
</style>
