<script>
	import { createEventDispatcher, onDestroy } from "svelte";
	import HCaptcha from "./HCaptcha.svelte";
	import HCaptchaModal from "./HCaptchaModal.svelte";
	import ProgressBar from "./ProgressBar.svelte";
	import Uploader from "./tools/b2Uploader.js";
	import Archiver from "./tools/cryptoArchiver";

	const dispatch = createEventDispatcher();
	const close = (e) => {
		e.preventDefault();
		dispatch("close");
	};

	const backendUrl = "https://file-api-app.inosida.com";

	//Uploader props
	export let color;
	export let hcaptchaKey;
	export let formId;
	export let maxUploadLimit;

	export let uploadFileId;
	export let uploadFileKey;
	export let reloadUpload;

	let startedZip = false;
	let startedUpload = false;
	let hcaptchaToken;
	let files;
	let filesArr = [];
	let uploadDone = false;
	let errorOccurred = false;
	let fileMsg = false;
	let percentageDone = 0;
	let captcha;

	const uploadInit = async () => {
		if (!(filesArr.length > 0)) return;

		startedZip = true;

		const max_upload_size = 100 * 1024 * 1024
		const max_number_of_files = 10
		const archiver = new Archiver(max_upload_size, max_number_of_files)

		archiver.addFiles(filesArr)
		let archive = await archiver.encrypt(null, (percentage) => {
			percentageDone = percentage;
		})
		console.log(archive)
		if(!archive) {
			return;
		}

		try {
			startedUpload = true;
			percentageDone = 0;
			const uploader = new Uploader(backendUrl, formId);
			const [data, error] = await uploader.upload(archive, (percentage) => {
				percentageDone = percentage;
			});
			if (error) {
				throw new Error(error.error);
			}
			uploadDone = true;
			uploadFileId = data.uploadFileId;
			uploadFileKey = data.uploadFileKey;
		} catch (err) {
			errorOccurred = true;
			console.error(err);
		}
	};

	$: if (files) {
		for (let i = 0; i < files.length; i++) {
			if (filesArr.some((file) => file.name == files[i].name)) {
				displayFileMsg();
			} else {
				filesArr.push(files[i]);
			}
		}
		files = null;
	}

	const displayFileMsg = () => {
		fileMsg = true;
		setTimeout(() => {
			fileMsg = false;
		}, 4000);
	};

	const removeFile = (e) => {
		e.preventDefault();
		let fileName = e.target.closest("a").dataset.value;
		filesArr = filesArr.filter(function (value, index, arr) {
			return value.name != fileName;
		});
		totalBytes = 0;
		filesArr.forEach((file) => (totalBytes += file.size));
	};

	let totalBytes = 0;
	$: if (filesArr) {
		totalBytes = 0;
		filesArr.forEach((file) => (totalBytes += file.size));
	}

	const bytesToSize = (bytes) => {
		const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
		if (bytes === 0) return "n/a";
		const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
		if (i === 0) return `${bytes} ${sizes[i]}`;
		return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
	};

	const fileTypeToSvg = (type) => {
		if (type.includes("image")) {
			//Image file
			return `<svg width="32" height="32" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-128-448v320h-1024v-192l192-192 128 128 384-384zm-832-192q-80 0-136-56t-56-136 56-136 136-56 136 56 56 136-56 136-136 56z"/></svg>`;
		} else if (type.includes("pdf")) {
			//PDF file
			return `<svg width="32" height="32" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-514-593q33 26 84 56 59-7 117-7 147 0 177 49 16 22 2 52 0 1-1 2l-2 2v1q-6 38-71 38-48 0-115-20t-130-53q-221 24-392 83-153 262-242 262-15 0-28-7l-24-12q-1-1-6-5-10-10-6-36 9-40 56-91.5t132-96.5q14-9 23 6 2 2 2 4 52-85 107-197 68-136 104-262-24-82-30.5-159.5t6.5-127.5q11-40 42-40h22q23 0 35 15 18 21 9 68-2 6-4 8 1 3 1 8v30q-2 123-14 192 55 164 146 238zm-576 411q52-24 137-158-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132 1-7 7-44 0-3 7-43 1-4 4-8-1-1-1-2-1-2-1-3-1-22-13-36 0 1-1 2v2zm-124 661q135-54 284-81-2-1-13-9.5t-16-13.5q-76-67-127-176-27 86-83 197-30 56-45 83zm646-16q-24-24-140-24 76 28 124 28 14 0 18-1 0-1-2-3z"/></svg>`;
		} else if (
			type.includes("presentation") ||
			type.includes("presentationml")
		) {
			//Powerpoint document file
			return `<svg width="32" height="32" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-992-234v106h327v-106h-93v-167h137q76 0 118-15 67-23 106.5-87t39.5-146q0-81-37-141t-100-87q-48-19-130-19h-368v107h92v555h-92zm353-280h-119v-268h120q52 0 83 18 56 33 56 115 0 89-62 120-31 15-78 15z"/></svg>`;
		} else if (
			type.includes("wordprocessingml") ||
			type.includes("msword") ||
			type.includes("ms-word")
		) {
			//Word document file
			return `<svg width="32" height="32" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-1175-896v107h70l164 661h159l128-485q7-20 10-46 2-16 2-24h4l3 24q1 3 3.5 20t5.5 26l128 485h159l164-661h70v-107h-300v107h90l-99 438q-5 20-7 46l-2 21h-4q0-3-.5-6.5t-1.5-8-1-6.5q-1-5-4-21t-5-25l-144-545h-114l-144 545q-2 9-4.5 24.5t-3.5 21.5l-4 21h-4l-2-21q-2-26-7-46l-99-438h90v-107h-300z"/></svg>`;
		} else if (type.includes("spreadsheetml")) {
			// Excel document file
			return `<svg width="32" height="32" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-979-234v106h281v-106h-75l103-161q5-7 10-16.5t7.5-13.5 3.5-4h2q1 4 5 10 2 4 4.5 7.5t6 8 6.5 8.5l107 161h-76v106h291v-106h-68l-192-273 195-282h67v-107h-279v107h74l-103 159q-4 7-10 16.5t-9 13.5l-2 3h-2q-1-4-5-10-6-11-17-23l-106-159h76v-107h-290v107h68l189 272-194 283h-68z"/></svg>`;
		} else if (type.includes("video")) {
			//Video file
			return `<svg width="32" height="32" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-640-896q52 0 90 38t38 90v384q0 52-38 90t-90 38h-384q-52 0-90-38t-38-90v-384q0-52 38-90t90-38h384zm492 2q20 8 20 30v576q0 22-20 30-8 2-12 2-14 0-23-9l-265-266v-90l265-266q9-9 23-9 4 0 12 2z"/></svg>`;
		} else if (type.includes("text/plain")) {
			//Txt file
			return `<svg width="32" height="32" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-1024-864q0-14 9-23t23-9h704q14 0 23 9t9 23v64q0 14-9 23t-23 9h-704q-14 0-23-9t-9-23v-64zm736 224q14 0 23 9t9 23v64q0 14-9 23t-23 9h-704q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h704zm0 256q14 0 23 9t9 23v64q0 14-9 23t-23 9h-704q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h704z"/></svg>`;
		} else if (type.includes("zip") || type.includes("compressed")) {
			//Compressed file
			return `<svg width="32" height="32" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M768 384v-128h-128v128h128zm128 128v-128h-128v128h128zm-128 128v-128h-128v128h128zm128 128v-128h-128v128h128zm700-388q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-128v128h-128v-128h-512v1536h1280zm-627-721l107 349q8 27 8 52 0 83-72.5 137.5t-183.5 54.5-183.5-54.5-72.5-137.5q0-25 8-52 21-63 120-396v-128h128v128h79q22 0 39 13t23 34zm-141 465q53 0 90.5-19t37.5-45-37.5-45-90.5-19-90.5 19-37.5 45 37.5 45 90.5 19z"/></svg>`;
		} else {
			// 'Plain' file
			return `<svg width="32" height="32" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280z"/></svg>`;
		}
	};

	onDestroy(() => {
		reloadUpload = false;
	});

	const reloadFileComponent = (e) => {
		e.preventDefault();
		reloadUpload = true;
	};

	const handleCaptchaError = () => {
		captcha.reset();
	};

	const handleSuccess = (token) => {
		setTimeout(() => {
			showHCModal = false;
		}, 1000);
		hcaptchaToken = token.detail.token;
		uploadInit();
	};

	let showHCModal = false;
	$: hcDisplay = showHCModal ? "block" : "none";
</script>

<div class="wrapper" style="--theme-color: {color}">
	<div style="display: {hcDisplay}">
		<HCaptchaModal on:close={() => (showHCModal = false)}>
			<form>
				<HCaptcha
					sitekey={hcaptchaKey}
					on:success={handleSuccess}
					on:error={handleCaptchaError}
				/>
			</form>
		</HCaptchaModal>
	</div>
	{#if fileMsg}
		<p id="file-message">
			Kunde inte lägga till fil. Filer kan inte ha samma namn.
		</p>
	{/if}
	{#if errorOccurred}
		<div class="modal-content">
			<div class="upload-progress">
				<svg
					width="64"
					height="64"
					viewBox="0 0 1792 1792"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z"
					/>
				</svg>
				<p class="progress-info">
					Ett fel inträffade. Testa gärna igen.
				</p>
			</div>
		</div>
	{:else if uploadDone}
		<div class="modal-content">
			<div class="upload-progress">
				<svg
					width="64"
					height="64"
					viewBox="0 0 1792 1792"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
					/>
				</svg>
				<ProgressBar fixed={percentageDone} />
				<p class="progress-info">Uppladdningen är klar!</p>
			</div>
		</div>
	{:else if startedUpload}
		<div class="modal-content">
			<div class="upload-progress">
				<svg
					width="64"
					height="64"
					viewBox="0 0 2048 1792"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M1344 864q0-14-9-23l-352-352q-9-9-23-9t-23 9l-351 351q-10 12-10 24 0 14 9 23t23 9h224v352q0 13 9.5 22.5t22.5 9.5h192q13 0 22.5-9.5t9.5-22.5v-352h224q13 0 22.5-9.5t9.5-22.5zm640 288q0 159-112.5 271.5t-271.5 112.5h-1088q-185 0-316.5-131.5t-131.5-316.5q0-130 70-240t188-165q-2-30-2-43 0-212 150-362t362-150q156 0 285.5 87t188.5 231q71-62 166-62 106 0 181 75t75 181q0 76-41 138 130 31 213.5 135.5t83.5 238.5z"
					/>
				</svg>
				<ProgressBar value={percentageDone} />
				<p class="progress-info">Laddar upp... {percentageDone}%</p>
			</div>
		</div>
	{:else if startedZip}
		<div class="modal-content">
			<div class="upload-progress">
				<svg
					width="64"
					height="64"
					viewBox="0 0 2048 1792"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M1344 864q0-14-9-23l-352-352q-9-9-23-9t-23 9l-351 351q-10 12-10 24 0 14 9 23t23 9h224v352q0 13 9.5 22.5t22.5 9.5h192q13 0 22.5-9.5t9.5-22.5v-352h224q13 0 22.5-9.5t9.5-22.5zm640 288q0 159-112.5 271.5t-271.5 112.5h-1088q-185 0-316.5-131.5t-131.5-316.5q0-130 70-240t188-165q-2-30-2-43 0-212 150-362t362-150q156 0 285.5 87t188.5 231q71-62 166-62 106 0 181 75t75 181q0 76-41 138 130 31 213.5 135.5t83.5 238.5z"
					/>
				</svg>
				<ProgressBar value={percentageDone} />
				<p class="progress-info">
					Zippar/krypterar... {percentageDone}%
				</p>
			</div>
		</div>
	{:else}
		<!-- Standard 'state' -->
		<div class="modal-content">
			<div id="input-wrapper">
				<input bind:files id="many" multiple type="file" title="" />
				<svg
					width="64"
					height="64"
					viewBox="0 0 2048 1792"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M1344 864q0-14-9-23l-352-352q-9-9-23-9t-23 9l-351 351q-10 12-10 24 0 14 9 23t23 9h224v352q0 13 9.5 22.5t22.5 9.5h192q13 0 22.5-9.5t9.5-22.5v-352h224q13 0 22.5-9.5t9.5-22.5zm640 288q0 159-112.5 271.5t-271.5 112.5h-1088q-185 0-316.5-131.5t-131.5-316.5q0-130 70-240t188-165q-2-30-2-43 0-212 150-362t362-150q156 0 285.5 87t188.5 231q71-62 166-62 106 0 181 75t75 181q0 76-41 138 130 31 213.5 135.5t83.5 238.5z"
					/>
				</svg>
				<p>
					Drag & släpp dokument här eller <span class="no-wrap"
						>välj en fil</span
					>
				</p>
				<p class="modal-info">
					Maximalt {bytesToSize(Number(maxUploadLimit))}
				</p>
			</div>
			<div class="file-container">
				{#if filesArr.length > 0}
					{#each filesArr as file}
						<div class="file-item">
							<div class="file-icon-container">
								{@html fileTypeToSvg(file.type)}
							</div>
							<p>{file.name} - {bytesToSize(file.size)}</p>
							<a
								class="remove-file"
								href=""
								data-value={file.name}
								on:click={removeFile}
								style="color:inherit"
							>
								<svg
									width="32"
									height="32"
									viewBox="0 0 1792 1792"
									fill="currentColor"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M704 736v576q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23v-576q0-14 9-23t23-9h64q14 0 23 9t9 23zm256 0v576q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23v-576q0-14 9-23t23-9h64q14 0 23 9t9 23zm256 0v576q0 14-9 23t-23 9h-64q-14 0-23-9t-9-23v-576q0-14 9-23t23-9h64q14 0 23 9t9 23zm128 724v-948h-896v948q0 22 7 40.5t14.5 27 10.5 8.5h832q3 0 10.5-8.5t14.5-27 7-40.5zm-672-1076h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"
									/>
								</svg>
							</a>
						</div>
					{/each}
				{/if}
			</div>
			{#if totalBytes > 0}
				<p class="files-info left-align">
					Totalt: {bytesToSize(totalBytes)} av Max {bytesToSize(
						Number(maxUploadLimit),
					)}
				</p>
			{:else}
				<p class="files-info left-align">
					Totalt: 0 Bytes av Max {bytesToSize(Number(maxUploadLimit))}
				</p>
			{/if}
			<div class="divider" />
			<p class="modal-info left-align">
				Genom att trycka på "Ladda upp" godkänner du vår <a href=""
					>integritetspolicy</a
				>
			</p>
		</div>
	{/if}
	{#if errorOccurred}
		<button class="uploadBtn" on:click={reloadFileComponent}
			>Testa igen</button
		>
	{:else if uploadDone}
		<button class="uploadBtn" on:click={close}
			>Tillbaka till formuläret</button
		>
	{:else if startedZip || startedUpload}
		<button class="uploadBtn disabled">Vänta...</button>
	{:else if totalBytes > maxUploadLimit}
		<button class="uploadBtn disabled"
			>Du får max ladda up {bytesToSize(Number(maxUploadLimit))}</button
		>
	{:else if totalBytes > 0}
		<button
			class="uploadBtn"
			on:click={(e) => {
				e.preventDefault();
				showHCModal = true;
			}}>Ladda upp</button
		>
	{:else}
		<button class="uploadBtn disabled">Välj en fil</button>
	{/if}
	<p class="modal-info">En tjänst av <strong>Inosida Webbyrå</strong></p>
</div>

<style>
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		color: #333;
	}

	#many {
		position: absolute;
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
		outline: none;
		opacity: 0;
	}

	#many:hover {
		cursor: pointer;
	}

	#input-wrapper {
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='%23F1F2F3' rx='5' ry='5' stroke='%23C8CCD0' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");
		border-radius: 5px;
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		height: 140px;
		overflow: hidden;
	}

	#input-wrapper p {
		margin: 0px 10px;
		text-align: center;
	}

	.modal-content {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.upload-progress {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		height: 230px;
		overflow: hidden;
	}

	.wrapper {
		margin-left: auto;
		margin-right: auto;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		height: 100%;
		color: var(--theme-color);
	}

	.uploadBtn {
		width: 100%;
		border: none;
		padding: 1rem 0rem;
		margin-bottom: 20px 0px;
		border-radius: 5px;
		color: white;
    	background-color: var(--theme-color);
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		margin: 20px 0px;
	}

	.uploadBtn:hover {
		cursor: pointer;
	}

	.disabled {
		pointer-events: none;
		color: white;
		background: #C8CCD0 !important;
	}

	.disabled:hover {
		cursor: default;
	}

	.file-container {
		margin: 10px 0px;
		max-height: 150px;
		width: 100%;
		overflow-y: auto;
	}

	.file-container::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: #f5f5f5;
	}

	.file-container::-webkit-scrollbar {
		width: 10px;
		background-color: #f5f5f5;
	}

	.file-container::-webkit-scrollbar-thumb {
		background-color: var(--theme-color);
		border: 2px solid #555555;
	}

	.left-align {
		align-self: flex-start;
	}

	.modal-info {
		margin: 5px 0px;
		font-weight: normal;
		font-size: 12px;
		line-height: 140%;
		letter-spacing: 0.015em;
	}

	.files-info {
		margin: 0px;
	}

	.divider {
		background: rgba(49, 65, 84, 0.5);
		width: 100%;
		height: 2px;
		margin: 20px 0px;
	}

	.file-item {
		width: 100%;
		display: flex;
		margin-bottom: 10px;
		align-items: center;
	}

	.file-item p {
		margin-left: 10px;
	}

	.remove-file {
		margin-left: auto;
		margin-right: 10px;
	}

	.file-icon-container {
		width: 32px;
	}

	.no-wrap {
		font-weight: bold;
		white-space: nowrap;
	}

	#file-message {
		position: absolute;
		top: 15px;
		z-index: 5;
		padding: 7px 15px;
		background: #fdc4c4;
		border-radius: 5px;
		min-width: 200px;
		font-size: 14px;
	}

	@media screen and (max-width: 510px) {
		#file-message {
			top: 2px;
			max-width: 60%;
		}
	}
</style>
