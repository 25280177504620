<script>
	import { createEventDispatcher, onDestroy } from "svelte";

	const dispatch = createEventDispatcher();
	const close = () => dispatch("close");

	let modal;

	const handle_keydown = (e) => {
		if (e.key === "Escape") {
			close();
			return;
		}

		if (e.key === "Tab") {
			// trap focus
			const nodes = modal.querySelectorAll("*");
			const tabbable = Array.from(nodes).filter((n) => n.tabIndex >= 0);

			let index = tabbable.indexOf(document.activeElement);
			if (index === -1 && e.shiftKey) index = 0;

			index += tabbable.length + (e.shiftKey ? -1 : 1);
			index %= tabbable.length;

			tabbable[index].focus();
			e.preventDefault();
		}
	};

	const previously_focused =
		typeof document !== "undefined" && document.activeElement;

	if (previously_focused) {
		onDestroy(() => {
			previously_focused.focus();
		});
	}
</script>

<svelte:window on:keydown={handle_keydown} />

<div class="modal-background" on:click={close} />

<div class="modal" role="dialog" aria-modal="true" bind:this={modal}>
	<div class="close" on:click={close}>
		<svg
			width="20"
			height="20"
			viewBox="0 0 1792 1792"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			><path
				d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z"
			/></svg
		>
	</div>
	<slot />
	<!-- svelte-ignore a11y-autofocus -->
</div>

<style>
	.close {
		position: absolute;
		top: 0;
		right: 0;
		margin: 10px;
		padding: 10px;
		height: 40px;
		width: 40px;
		color: #333;
	}

	.close:hover {
		cursor: pointer;
		color: rgb(32, 32, 32);
	}

	.modal-background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.3);
		z-index: 999;
	}

	.modal {
		position: fixed;
		left: 50%;
		top: 50%;
		width: calc(100vw - 4rem);
		max-width: 32rem;
		max-height: calc(100vh - 4rem);
		overflow: auto;
		transform: translate(-50%, -50%);
		padding: 4em 1rem 1rem 1rem;
		border-radius: 0.2rem;
		background: white;
		z-index: 999;
	}

	@media (max-width: 500px) {
		.modal {
			position: fixed;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			transform: none;
			border-radius: 0;
			height: auto;
			max-height: none;
			max-width: none;
			width: auto;
		}
	}
</style>
