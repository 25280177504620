import App from './App.svelte';
let root = document.querySelector("[data-file-upload]");
var app = new App({
	target: root,
    props: {
        color: root.getAttribute("icon-color"),
        hcaptchaKey: root.getAttribute("hcaptchaKey"),
        formId: root.getAttribute("formId"),
        maxUploadLimit: root.getAttribute("max-upload-limit")
    }
});

export default app;