<script>
	import { onDestroy, createEventDispatcher, onMount } from "svelte";

	const dispatch = createEventDispatcher();

	export let sitekey = window.sitekey || undefined;
	export let hl = "sv";
	export let reCaptchaCompat = "off";
	export let theme = "light";

	export const reset = () => {
		if (mounted && loaded && widgetID) hcaptcha.reset(widgetID);
	};

	// ensure that all captcha divs on a page are uniquely identifiable
	const id = Math.floor(Math.random() * 100);

	let mounted = false;
	let loaded = false;
	let widgetID;

	// construct the script tag for hCaptcha remote resources
	const query = new URLSearchParams({
		recaptchacompat: reCaptchaCompat || "",
		onload: "hcaptchaOnLoad",
	});
	const scriptSrc = `https://js.hcaptcha.com/1/api.js?${query.toString()}`;

	window.hcaptchaOnLoad = () => {
		// consumers can attach custom on:load handlers
		dispatch("load");
		loaded = true;
	};

	window.onSuccess = (token) => {
		dispatch("success", {
			token: token,
		});
	};

	window.onError = () => {
		dispatch("error");
	};

	window.onClose = () => {
		dispatch("close");
	};

	onMount(() => {
		dispatch("mount");
		mounted = true;
	});

	onDestroy(() => {
		window.hcaptchaOnLoad = null;
		window.onSuccess = null;
		// guard against script loading race conditions
		// i.e. if component is destroyed before hcaptcha reference is loaded
		if (loaded) hcaptcha = null;
	});

	$: if (mounted && loaded) {
		widgetID = hcaptcha.render(`h-captcha-${id}`, {
			sitekey: sitekey,
			hl: hl, // force a specific localisation
			theme: theme || "light",
			callback: "onSuccess",
			"error-callback": "onError",
			"close-callback": "onClose",
		});
	}
</script>

<svelte:head>
	<script src={scriptSrc} async defer>
	</script>
</svelte:head>

<div id="h-captcha-{id}" />
