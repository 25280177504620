<script>
	import Modal from './Modal.svelte';
	import File from './File.svelte';
	
	//Uploader props
    export let color;
	export let hcaptchaKey;
	export let formId;
	export let maxUploadLimit;

	let uploadFileId;
	let uploadFileKey;


	let reloadUpload;

	let showModal = false;
	$: display = showModal ? "block" : "none";

	$: if (uploadFileId && uploadFileKey) {
		document.querySelector('[data-upload-container]').style.display = "none";
		document.querySelector('[data-upload-result]').style.display = "block";
	}

	document.querySelector('[data-upload-button]').addEventListener('click', (e) => {
		e.preventDefault();
		showModal = true;
	});

</script>
{#if uploadFileId && uploadFileKey}
	<input name="_fileId" type="hidden" value={uploadFileId} readonly/>
	<input name="_fileKey" type="hidden" value={uploadFileKey} readonly/>
{/if}

<div style="display: {display}">
	{#if reloadUpload}
		<Modal on:close="{() => showModal = false}">
			<File bind:reloadUpload={reloadUpload} bind:uploadFileId={uploadFileId} bind:uploadFileKey={uploadFileKey} formId={formId} hcaptchaKey={hcaptchaKey} color={color} maxUploadLimit={maxUploadLimit} on:close="{() => showModal = false}"></File>
		</Modal>
	{:else}
		<Modal on:close="{() => showModal = false}">
			<File bind:reloadUpload={reloadUpload} bind:uploadFileId={uploadFileId} bind:uploadFileKey={uploadFileKey} formId={formId} hcaptchaKey={hcaptchaKey} color={color} maxUploadLimit={maxUploadLimit} on:close="{() => showModal = false}"></File>
		</Modal>
	{/if}

</div>